.custom-modal-extend-cut-off {
    width: 558px !important;
    margin: auto;

    .ant-modal-header {
        border-radius: 12px !important;
        background-color: var(--background-primary-4);
        border: 0;

        .ant-modal-title {
            font-weight: 700;
            font-size: 20px;
            line-height: 140%;
            color: var(--text-third);
        }
    }

    .ant-modal-content {
        background-color: var(--background-primary-4);
        border-radius: 12px;
        border: 0;

        section {
            display: flex;
            flex-direction: column;
            gap: 32px;
        }

        .extend-cut-off-band {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            padding: 2px 12px;
            height: 55px;
            background-color: var(--background-fifth);
            box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px -1px rgba(0, 0, 0, 0.1);
            border-radius: 4px;

            span {
                color: var(--text-primary);
            }

            p {
                font-weight: 600;
                font-size: 18px;
                line-height: 150%;
                color: var(--text-primary);
                margin-bottom: 0;
            }

            .ant-input {
                background-color: var(--background-fifth);
                border: none;
                text-align: center;
                width: 50px;
                padding: 5px;
                margin: 0
            }
        }

        .ph-2 {
            padding-left: 2rem;
            padding-right: 2rem;
        }

        .extend-cut-off-select {
            display: flex;
            align-items: center;
            justify-content: space-between;

            span {
                color: var(--text-primary);
            }
        }

        .extend-cut-off-chip {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 8px;
            background: var(--background-primary);
            border-radius: 8px;

            span {
                font-weight: 500;
                font-size: 16px;
                line-height: 150%;
                color: #8C8C8C;
            }
        }

        .ant-select-selector {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 8px 16px;
            max-width: 250px;
            gap: 10px;
            background-color: var(--background-fifth) !important;
            border-radius: 2px;
        }

        .extend-cut-off-expires {
            font-weight: 600;
            color: var(--text-primary);
        }

        .ant-btn-save {
            height: auto;
            padding: 10px;
            background-color: transparent;
            color: var(--text-third) !important;
        }
    }


}