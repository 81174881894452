.custom-modal-return-to-inventory {
    .ant-modal-content {
        display: flex;
        flex-direction: column;
        border-radius: 24px;
        width: 700px;

        h2 {
            margin-top: 2rem;
            font-weight: 700;
            font-size: 36px;
            line-height: 120%;
            color: #000000;
        }

        p {
            font-weight: 500;
            font-size: 14px;
            line-height: 150%;
            margin-top: 16px;

            span {
                font-weight: bold;
            }
        }

        input {
            padding: 8px 24px 12px;
            background: var(--background-fifth);
            box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px -1px rgba(0, 0, 0, 0.1);
            border-radius: 4px;
            border: 0;
        }

    }

    .ant-modal-footer {
        border-top: 0;
    }

    .text-confirmation {
        font-weight: 700;
        font-size: 28px;
    }
}