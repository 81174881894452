.awb-modal {
    padding: 24px;
    width: 1200px !important;
    position: fixed !important;
    top: 0;
    right: 0;
    margin: 0;

    .modal-title {
        font-size: 28px;
        font-weight: bold;
        margin-bottom: 16px;
        color: var(--text-primary);

    }

    .form-row {
        display: flex;
        gap: 24px;
        margin-bottom: 16px;

        .form-group {
            flex: 1;
        }
    }

    .form-group {
        margin-bottom: 16px;

        input {
            border: 0 !important;
            max-width: 300px;
        }

        .ant-picker,
        input:focus {
            outline: none !important;
            box-shadow: none !important;
        }

        .ant-picker {
            border: 0 !important;
            width: 200px;
        }

        label {
            display: block;
            margin-bottom: 8px;
            font-weight: 700;
            font-size: 16px;
            line-height: 150%;

        }
    }

    .ant-modal-content {
        border-radius: 0 !important
    }

    h2 {
        font-size: 24px;
        font-weight: bold;
        margin-bottom: 16px;
    }

    .pieces-container {
        margin-bottom: 16px;
    }

    .pieces-row {
        .pieces-header {
            display: flex;
            font-weight: 500;
            padding: 12px 0;
        }

        .header-cell {
            flex: 1;
            padding: 0 12px;
            font-weight: 700;
            font-size: 16px;
            line-height: 150%;
        }

        .pieces-data {
            display: flex;
            padding: 12px 0;
        }

        .data-cell {
            flex: 1;
            padding: 0 12px;
        }
    }

    .modal-footer {
        display: flex;
        justify-content: flex-end;
        gap: 16px;
        margin-top: 24px;

        .save-btn {
            background-color: #4c77b3;
            border-color: #4c77b3;
        }

        .close-btn {
            background-color: #b93a2c;
            border-color: #b93a2c;
            color: white;
        }
    }
}