@import '../base/variables.scss';

.custom-modal-sent-email-types {
  .ant-modal-header {
    background-color: var(--background-sixth-button) !important;
    height: 40px;
    width: 600px;
    padding: 10px 16px !important;
    align-items: center;

    .ant-modal-title {
      font-weight: 600;
      color: var(--text-sixth);
    }
  }

  .ant-modal-close-x {
    width: 32px;
    height: 40px;
    font-size: 16px;
    line-height: 40px;

    svg {
      color: var(--text-sixth) !important;
    }
  }

  .ant-modal-content {
    width: 600px;
    border-radius: 0px;

    .ant-divider-horizontal {
      margin: 5px 0;
    }
  }

  .ant-modal-body {
    padding: 10px;
  }

  .ant-modal-footer {
    text-align: left;

    svg {
      color: var(--text-sixth) !important;
    }
  }

  .body-date {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    p {
      margin-bottom: 2px;
      font-weight: 300;
      font-size: 14px;
      color: var(--text-four-2);
    }
  }

  pre {
    max-width: 100%;
    overflow: hidden;
    text-align: left;
    text-wrap: inherit;
    font-family: "Poppins";
    font-weight: 400;
    line-height: 150%;
  }

  .send-button {
    height: 36px;
    background-color: var(--background-sixth-button);

    span {
      font-weight: 500;
      font-size: 14px;
      line-height: 120%;
      color: var(--text-sixth);
    }
  }
}

@media screen and (max-width: 550px) {
  .custom-modal-sent-email-types {
    position: initial;
    display: flex;
    justify-content: center;
    align-items: center;

    .ant-modal-header {
      width: 100%;
    }

    .ant-modal-content {
      width: 90%;
    }

    .body-date p {
      margin-right: 0px !important;
    }
  }
}