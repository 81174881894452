@import './../base/variables.scss';

.ant-table {
    width: 70vw;
    min-height: 40vh;
}

.ant-table-wrapper {
    display: flex;
    justify-content: center;
    min-height: max-content;
    background-image: var(--background-image-opacity);
    background-size: cover;
}

.ant-table-pagination.ant-pagination {
    background-color: var(--backgroud-secundary);
    height: 62px;
    margin: 0px;
    display: flex;
    align-items: center;
}

.table-multiple-container {
    .ant-table.ant-table-bordered>.ant-table-container {
        width: 40vw !important;
    }

    .ant-table-thead > tr > th {
        font-family: Poppins;
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
        text-align: left;
        background-color: transparent;
        border: 0px;
    }

    .ant-table-tbody > tr > td{
        border: 0px;
    }

    .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > thead > tr > th{
        border: 0px;
    }

    .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tbody > tr > td{
        border: 0px;
    }

    .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table{
        border: 0px;
    }

    .ant-table.ant-table-bordered > .ant-table-container{
        border: 0px;
    }

    .ant-table{
        min-height: 5vh;
    }
}

.km-table-sm {
    .ant-list-items{
        background-image: var(--background-image-opacity);
    }
    .ant-list-item{
        padding-top: 5px!important;
        padding-bottom: 0px;
    }
    .ant-card-head{
        border: 0px!important;
    }
}

.km-card-item-table{
    .ant-card-head-title{
        color:  var(--text-primary)!important;
        text-transform: uppercase;
        font-weight: 600;
        background-color: var(--backgroud-secundary);
        border-radius: 8px;
        height: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 15px;
    }
}