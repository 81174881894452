::-webkit-scrollbar {
    width: 8px;
    height: 6px;
}

::-webkit-scrollbar-button {
    width: 0px;
    height: 0px;
}

::-webkit-scrollbar-thumb {
    background: #a5a6a8;
    border: 0px none #ffffff;
    border-radius: 50px;
}

::-webkit-scrollbar-thumb:hover {
    background: #636468;
}

::-webkit-scrollbar-thumb:active {
    background: #636468;
}

::-webkit-scrollbar-track {
    background: #cccccc;
    border: 0px none #ffffff;
    border-radius: 50px;
}

::-webkit-scrollbar-track:hover {
    background: #bebebe;
}

::-webkit-scrollbar-track:active {
    background: #d7d7d7;
}

::-webkit-scrollbar-corner {
    background: transparent;
}