@import '../base/variables.scss';

.ant-btn-primary {
   background-color: var(--background-eighth);
   color: var(--text-primary);
   border-radius: 12px;
   box-shadow: 0px 12px 12px rgba(0, 0, 0, 0.1);
   margin: 0 auto;
   border: 0px;
   height: 60px;
   line-height: 140%;
   font-size: 20px;
   padding: 5px 65px;
   width: 100% !important;
}

.ant-btn-primary:hover {
   background-color: var(--background-eighth) !important;
   color: var(--text-primary) !important;
   opacity: 0.7;
}

.ant-btn-save {
   border-radius: 6px;
   width: auto;
   height: 56px;
   font-size: 16px;
   padding-left: 35px;
   padding-right: 35px;
   color: white;
   font-weight: bold;
   line-height: 150%;
   background-color: var(--background-sixth-button);
   color: var(--text-sixth) !important;
   border: 0px;
}

.ant-btn-save:hover {
   background-color: var(--background-sixth-button);
   color: var(--text-sixth) !important;
   opacity: 0.7;
}

.ant-btn-close {
   border-radius: 6px;
   width: auto;
   height: 56px;
   font-size: 16px;
   padding-left: 35px;
   padding-right: 35px;
   color: white;
   font-weight: bold;
   line-height: 150%;
   background-color: var(--background-seventh);
   color: var(--text-sixth) !important;
   border: 0px;
}

.ant-btn-close:hover {
   background-color: var(--background-seventh);
   color: var(--text-sixth) !important;
   opacity: 0.7;
}

.ant-btn-icon {
   background-color: var(--background-four);
   border-color: var(--border-four-2);
   border-radius: 8px;
   height: 40px;

   span {
      color: var(--text-primary) !important;
   }
}

.ant-btn-icon:hover {
   border-color: var(--border-four-2);
   background-color: var(--background-four);
}

.ant-picker-panel {
   .ant-btn-primary {
      background-color: var(--background-eighth);
      font-size: 14px;
      font-weight: 400;
      border-radius: 4px;
      padding: 2px 8px;
      height: auto;
   }
}