.assign-inventory-modal-information {
    width: 550px !important;
    margin: auto;

    .ant-modal-header {
        border-radius: 12px !important;

        .ant-modal-title {
            font-weight: 700;
            font-size: 32px;
            line-height: 130%;
            color: var(--text-third);
        }
    }

    .ant-modal-content {
        border-radius: 12px;

        .assign-inventory-body {
            margin-bottom: 32px;

            p {
                font-weight: 700;
                font-size: 18px;
                line-height: 150%;
                color: var(--text-primary);
                margin-bottom: 0;
            }

            span {
                font-weight: 400;
            }
        }

        .assign-inventory-boxes {
            display: flex;
            flex-direction: column;

            label {
                font-weight: 700;
                font-size: 18px;
                color: var(--text-primary);
            }

            textarea {
                padding: 12px 20px;
                height: 64px;
                background: var(--background-fifth);
                box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px -1px rgba(0, 0, 0, 0.1);
                border-radius: 4px;
                border: 0px;
                resize: none;
            }

            textarea:focus{
                outline: none;
            }
        }
    }

}