@import '../base/variables.scss';

.custom-modal-sent-message {
    width: 65dvw !important;
    position: fixed !important;
    top: 0;
    right: 0;
    margin: 0;
    border-radius: 0 !important;

    .ant-modal-header {

        .ant-modal-title {
            font-weight: 600;
            font-size: 40px;
            line-height: 60px;
        }
    }

    .ant-modal-content {
        border-radius: 0 !important;

        p {
            font-weight: 500;
            font-size: 20px;
        }

        h3 {
            font-weight: 700;
            font-size: 20px;
        }
    }


    .btn-container-resend {
        display: flex;
        justify-content: flex-end;
    }

    .sent-message-footer {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 16px;
    }

    .ant-table {
        width: 65dvw !important;
    }
}

@media screen and (max-width: 550px) {
    .custom-modal-sent-message {
        width: 100% !important;
        position: initial !important;
        margin-left: auto !important;
        margin-right: auto !important;
    }
}