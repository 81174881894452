.modal-add-country {
    width: 558px !important;
    margin: auto;

    .ant-modal-header {
        border-radius: 12px !important;
        background-color: var(--background-primary-4);
        border: 0;
        margin-bottom: 24px;

        .modal-title {
            padding-top: .5rem;
            font-size: 32px;
            font-weight: bold;
            color: var(--text-primary);
            margin: 0;
        }
    }

    .ant-modal-content {
        background-color: var(--background-primary-4);
        border-radius: 12px;
        border: 0;


    }

    .form-label {
        margin-bottom: 16px;
        color: var(--text-primary);
    }

    .ant-select-selector {
        text-align: left;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px -1px rgba(0, 0, 0, 0.1);
        border-radius: 4px;
        height: 47px;
        margin-top: 1rem;
    }

    input {
        display: flex;
        align-items: center;
        padding: 8px 24px 12px;
        background: var(--background-fifth);
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px -1px rgba(0, 0, 0, 0.1);
        border-radius: 4px;
        border: 0px;
        margin-top: 1rem;
        height: 47px;
    }

    .button-container {
        display: flex;
        justify-content: flex-end;
        gap: 12px;
    }

    .ant-btn-close,
    .ant-btn-save {
        height: 40px;
        padding-left: 25px;
        padding-right: 25px;
    }
}