@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");
@import "base/variables.scss";
@import "base/scroll.scss";
@import "base/font.scss";
@import "base/buttom.scss";
@import "base/form.scss";
@import "base/util.scss";
@import "base/layout.scss";
@import "base/table.scss";
@import "base/badges.scss";
@import "base/notifications.scss";
@import "base/dropdown-menu.scss";
@import "base/drawer.scss";
@import "base/steps.scss";
@import "modals/modal-sent-message.scss";
@import "modals/modal-sent-email-types.scss";
@import "modals/modal-return-to-inventory.scss";
@import "modals/modal-assign-inventory.scss";
@import "modals/modal-extend-cut-off.scss";
@import "modals/modal-activity-details.scss";
@import "modals/modal-add-country.scss";
@import "modals/modal-awb-information.scss";