.filter-drowndown {
  background-color: var(--background-primary-4);
  width: 250px;
  height: auto;
  padding-top: 10px;
  padding-left: 10px;

  h1 {
    color: var(--text-third);
    font-size: 24px;
    font-weight: bold;
    line-height: 140%;
  }

  label {
    color: var(--text-primary);
  }

}

.ant-dropdown-menu-item:hover {
  background-color: var(--background-four);
  width: 100%;
}

.ant-select-dropdown {
  cdk-virtual-scroll-viewport {
    height: 150px !important;
  }
}

.filter-button-apply {
  color: var(--text-third);
  font-weight: 500;
  font-size: 15px;
  line-height: 150%;
  justify-content: end;
}