@import '../base/variables.scss';

body{
    font-family: 'Poppins', sans-serif;
}
.tittle-large{
    font-size: 40px;
    font-weight: bold;
    line-height: 120%;
    color: var(--text-primary);

}

.title-large-secondary{
    font-size: 20px;
    font-weight:bold;
    line-height: 140%;
    color: var(--text-third);
}

.km-font-bold{
    font-weight: bold;
}