.custom-modal-activity-details {
    width: 703px !important;
    margin: auto;

    .ant-modal-header {
        border-radius: 12px !important;
        background-color: var(--background-primary-4);
        border: 0;

        .ant-modal-title {
            font-weight: 700;
            font-size: 32px;
            line-height: 140%;
            color: var(--text-third);
        }
    }

    .ant-modal-content {
        background-color: var(--background-primary-4);
        border-radius: 12px;
        border: 0;

        section {
            display: flex;
            flex-direction: column;

            div {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                gap: 10px
            }

            span {
                font-weight: 500;
                font-size: 18px;
                line-height: 150%;
                color: var(--text-primary);
            }

            p {
                font-weight: 700;
                font-size: 18px;
                line-height: 150%;
                color: var(--text-primary);
                margin: 0
            }
        }

        .ant-btn-save {
            height: auto;
            padding: 10px 26px;
        }
    }


}