$brand-primary: #4772a9;
$backgroud-page: #ffffff;
$background-primary-2: #ffffff;
$background-primary-3: #ffffff;
$background-primary-4: #ffffff;
$backgroud-secundary: #edf1f6;
$background-secundary-2: #edf1f6;
$background-secundary-3: #edf1f6;
$background-secundary-4: #edf1f6;
$background-third: #c6d3e4;
$background-four: #f0f0f0;
$background-four-2: #f0f0f0;
$background-four-3: #f0f0f0;
$background-fifth: #f5f5f5;
$background-sixth: #4772a9;
$background-seventh: #990000;
$background-eighth: #91deef;
$background-eighth-2: #91deef;
$background-nine: #ffffff;
$background-ten: #fcfcfc;
$border-primary: #000000;
$border-secondary-2: #f0f0f0;
$border-secondary-3: #f0f0f0;
$border-third: #c6d3e4;
$border-four: #d9d9d9;
$border-four-2: #d9d9d9;
$border-six: #ffffff;
$border-seven: #325178;
$text-primary: #000000;
$text-option-2: #000000;
$text-secondary: #595959;
$text-third: #4772a9;
$text-four: #bfbfbf;
$text-four-2: #8c8c8c;
$text-four-3: #8c8c8c;
$text-four-4: #8c8c8c;
$text-four-5: #8c8c8c;
$text-sixth: #ffffff;
$text-sixth-2: #ffffff;
$notification-error: #E60000;
$light-theme-colors: (
  brand-primary: #4772a9,
  backgroud-page: #ffffff,
  background-primary:#FFFFFF,
  background-primary-2: #ffffff,
  background-primary-3: #ffffff,
  background-primary-4: #ffffff,
  backgroud-secundary: #edf1f6,
  background-secundary-2: #edf1f6,
  background-secundary-3: #edf1f6,
  background-secundary-4: #edf1f6,
  background-third: #c6d3e4,
  background-four: #f0f0f0,
  background-four-2: #f0f0f0,
  background-four-3: #f0f0f0,
  background-fifth: #f5f5f5,
  background-sixth: #4772a9,
  background-sixth-button:#4772a9,
  background-seventh: #990000,
  background-eighth: #91deef,
  background-eighth-2: #91deef,
  background-nine: #ffffff,
  background-ten: #fcfcfc,
  border-primary: #000000,
  border-secondary-2: #f0f0f0,
  border-secondary-3: #f0f0f0,
  border-third: #c6d3e4,
  border-four: #d9d9d9,
  border-four-2: #d9d9d9,
  border-six: #ffffff,
  border-seven: #325178,
  text-primary: #000000,
  text-option-2: #000000,
  text-secondary: #595959,
  text-third: #4772a9,
  text-four: #bfbfbf,
  text-four-2: #8c8c8c,
  text-four-3: #8c8c8c,
  text-four-4: #8c8c8c,
  text-four-5: #8c8c8c,
  text-sixth: #ffffff,
  text-sixth-2: #ffffff,
  green-primary: #17DB94,
  icon-primary:#000000,
  icon-secondary:#4772A9,
  icon-thid:#BFBFBF,
  icon-four:#FFFFFF,
  icon-five:#4772A9,
  icon-six:#FFFFFF,
  notification-error: #E60000,
  background-image-opacity:url('../../assets/images/background-opacity-light.png'),
  logo-km:url('../../assets/images/logo.png'),
  wave-login:url('../../assets/images/wave.svg')
);

$dark-theme-colors: (
  brand-primary: #354053,
  backgroud-page: #354053,
  background-primary:#FFFFFF,
  background-primary-2: #273F5D,
  background-primary-3: #41689A,
  background-primary-4: #273F5D,
  backgroud-secundary: #354053,
  background-secundary-2: #273F5D,
  background-secundary-3: #325178,
  background-secundary-4: #325178,
  background-third: #6C8EBA,
  background-four: #1E3047,
  background-four-2: #273F5D,
  background-four-3: #41689A,
  background-fifth: #1E3047,
  background-sixth: #FFFFFF,
  background-sixth-button:#4772a9,
  background-seventh: #990000,
  background-eighth: #7C838F,
  background-eighth-2: #3fa9ff,
  background-nine: #6C8EBA,
  background-ten: #354053,
  border-primary: #000000,
  border-secondary-2: #41689A,
  border-secondary-3: #1E3047,
  border-third: #C6D3E4,
  border-four: #D9D9D9,
  border-four-2: #0D1015,
  border-six: #2D3647,
  border-seven: #D9D9D9,
  text-primary: #FFFFFF,
  text-option-2: #000000,
  text-secondary: #FFFFFF,
  text-third: #FFFFFF,
  text-four: #bfbfbf,
  text-four-2: #FFFFFF,
  text-four-3: #D9D9D9,
  text-four-4: #F0F0F0,
  text-four-5: #000000,
  text-sixth: #FFFFFF,
  text-sixth-2: #000000,
  green-primary: #17DB94,
  icon-primary:#FFFFFF,
  icon-primary-2:#000000,
  icon-secondary:#000000,
  icon-third:#BFBFBF,
  icon-four:#FFFFFF,
  icon-five:#000000,
  icon-six:#4772A9,
  notification-error: #E60000,
  background-image-opacity:url('../../assets/images/background-opacity-dark.png'),
  logo-km:url('../../assets/images/logo-dark.png'),
  wave-login:url('../../assets/images/wave-dark.svg')
);

@mixin theme-variables($theme) {
  @each $key, $value in $theme {
    --#{$key}: #{$value};
  }
}

body.light-theme {
  @include theme-variables($light-theme-colors);
}

body.dark-theme {
  @include theme-variables($dark-theme-colors);
}

body {
  background-color: var(--background-primary-2);

}