.mt-40 {
    margin-bottom: 40px;
}

.mb-10 {
    margin-bottom: 10px;
}

.mb-1 {
    margin-bottom: 1px;
}

.mt-1 {
    margin-top: -1rem;
}

.mt-10 {
    margin-top: 10px
}

.mt-40 {
    margin-top: 40px;
}
.mt-60 {
    margin-top: 60px;
}

.mt-18 {
    margin-top: 18px;
}

.mt-32 {
    margin-top: 32px;
}

.mt-39 {
    margin-top: 39px;
}

.mr-15 {
    margin-right: 15px;
}

.mt-20 {
    margin-top: 20px;
}

.mr-60 {
    margin-right: 60px;
}

.ml-20 {
    margin-left: 20px;
}

.mr-50 {
    margin-right: 50px;
}

.ml-60 {
    margin-left: 60px;
}
.ml-43{
    margin-left: 43px;
}

.ml-43 {
    margin-left: 43px;
}

.ml-8 {
    margin-left: 8px
}

.ml-16 {
    margin-left: 16px
}

.ml-24 {
    margin-left: 24px;
}

.gp-40 {
    gap: 40px;
}

.w-100 {
    width: 100%;
}

.w-75 {
    width: 75%;
}

.w-50 {
    width: 50%;
}

.ml-76 {
    margin-left: 76px;
}

.mt-32 {
    margin-top: 32px;
}

.ant-statistic-content-value {
    font-size: 12px;
    justify-content: center;
}

.mt-4 {
    margin-top: 4px;
}

.m-0 {
    margin: 0;
}

.ant-checkbox-inner {
    width: 23.6px;
    height: 24px;
    border-radius: 4px;
    border-color: var(--text-primary);
    border-width: 3px;
}

.km-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.border-none{
    border: 0px;
}

.fs-18{
    font-size: 18px;
}

.cursor-p{
    cursor: pointer;
}

.km-underline{
   text-decoration: underline;
}

.km-text-primary {
    color: $text-primary;
}

.km-none-a{
    color: var(--text-primary);
}

.p-30{
    padding: 30px;
}

.truncate {
    width: 100px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.primary-text{
    color: var(--text-primary)!important;
}