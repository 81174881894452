@import '../base/variables.scss';

.km-forgot-password-form-header {
  h1 {
    margin-bottom: 30px !important;
  }
}

.inner-content,
.inner-content-tab {
  padding: 25px 0px !important;
}

.km-menu {
  color: var(--background-sixth) !important;
}

.ant-layout {
  background: var(--backgroud-secundary);
}

nz-header {
  background: var(--background-third) !important;
  padding: 0;
}

nz-content {
  margin: 0 16px !important;
}

nz-breadcrumb {
  margin: 16px 0 !important;
}

.ant-menu-item {
  color: var(--background-sixth) !important;
  background: var(--backgroud-page);
}

.ant-menu-title-content {
  color: var(--text-primary);
}

.ant-dropdown-menu {
  background: var(--background-primary-4);
}

.ant-layout-sider {
  background: var(--backgroud-page);
}

.inner-content {
  padding: 24px;
  background: var(--backgroud-page);
  min-height: 80vh;
  border-radius: 15px;
}

.inner-content-tab {
  @extend .inner-content;
  border-radius: 0px 15px 15px;
}

.ant-layout-sider-collapsed {
  a>span {
    display: none;
  }

  nz-badge {
    display: none;
  }
}

.ant-menu-item a {
  color: var(--background-sixth) !important;
  padding-left: 10px;
  padding-right: 10px;
}

.km-menu .ant-layout-sider-trigger {
  background: var(--backgroud-page);

  span {
    color: var(--background-sixth) !important;
  }
}

.km-sider-footer {
  display: flex;
  justify-content: end;
}

.ant-menu-submenu-inline {
  color: var(--background-sixth) !important;
}

.ant-menu-submenu-vertical {
  color: var(--background-sixth) !important;
}

.ant-menu.ant-menu-inline.ant-menu-sub {
  background: var(--backgroud-page) !important;
}



.km-layout-title {
  padding: 20px;

  .tittle-large {
    margin: 0px;
  }
}

.km-layout-tab {
  .ant-radio-button-wrapper {
    border-radius: 8px 8px 0px 0px !important;
    border: 0px;
    margin-right: 2px;
    height: 40px;
    line-height: 45px;
    background-color: var(--background-four);
    color: var(--text-four-2);
  }

  .ant-radio-button-wrapper::before {
    background-color: transparent;
  }

  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):active::before {
    background-color: transparent !important;
  }

  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover::before {
    background-color: transparent !important;
  }

 .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    color: var(--text-third);
    font-weight: 600;
    box-shadow: 0px -15px 28px -3px rgba(0, 0, 0, 0.1) !important;
    background-color: var(--background-primary-3) !important;
  }

  .km-select-active {
    color: var(--text-third);
    font-weight: 600;
    box-shadow: 0px -15px 28px -3px rgba(0, 0, 0, 0.1) !important;
    background-color: var(--background-primary-3) !important;
  }
}

nz-content {
  margin: 0 1px !important;
}

.ant-menu {
  background: var(--backgroud-page);
}

.ant-dropdown-menu-item-selected {
  background: var(--background-fifth);
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background: var(--background-fifth);
}

.ant-table {
  background-color: var(--background-primary-4);
}

.ant-drawer-content {
  background-color: var(--background-primary-4);
}

.ant-steps-item-process>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-description,
.ant-steps-item-process>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title {
  color: var(--text-four-4);
}

.ant-steps-item-wait>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-description,
.ant-steps-item-wait>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title {
  color: var(--text-four-4);

}

.ant-form-item-label>label {
  color: var(--text-primary)
}

.ant-modal-content {
  border-radius: 24px;
}


.notification-container {
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, .12),
    0 6px 16px 0 rgba(0, 0, 0, .08),
    0 9px 28px 8px rgba(0, 0, 0, .05);
  padding: 20px;
  border-radius: 0px 0px 12px 12px;
  background-color: var(--background-primary-2) !important;

  .tittle-dropdown-menu {
    background-color: var(--background-primary-2) !important;
  }

  .ant-dropdown-menu,
  .ant-menu {
    box-shadow: none !important;
  }

  .tittle-dropdown-menu {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .ant-dropdown-menu-item {
    background-color: var(--backgroud-secundary) !important;
    padding: 5px 20px;
    margin: 5px;
    margin-top: 10px;
    border-radius: 12px;
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, .12),
      0 6px 16px 0 rgba(0, 0, 0, .08),
      0 9px 28px 8px rgba(0, 0, 0, .05);
  }
}

.my-account-container {
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, .12),
    0 6px 16px 0 rgba(0, 0, 0, .08),
    0 9px 28px 8px rgba(0, 0, 0, .05);
  padding: 20px;
  border-radius: 12px 0px 12px 12px;
  background-color: var(--background-primary-2) !important;

  .tittle-dropdown-menu {
    background-color: var(--background-primary-2) !important;
  }

  .ant-dropdown-menu,
  .ant-menu {
    box-shadow: none !important;
  }

  .ant-dropdown-menu-item {
    padding: 10px;
  }

  .ant-dropdown-menu-item:hover {
    background-color: var(--backgroud-secundary) !important;
  }
}


.chart-container {
  .apexcharts-legend-text {
    color: var(--text-primary) !important;
  }
}

.inner-content,
.inner-content-tab {
  background-color: var(--background-primary-2);
}

.ant-menu-inline .ant-menu-item {
  margin: 0px;
  padding: 22px 16px;
}

.ant-menu-inline .ant-menu-item:not(:last-child) {
  margin: 0px;
  padding: 22px 16px;
}


.ant-layout-sider-zero-width-trigger {
  position: fixed;
  top: 12px;
  left: 8px;
  background: var(--background-third) !important;
}

.ant-layout-sider-zero-width-trigger:hover {
  background: var(--background-third) !important;
}


.ant-breadcrumb {
  color: var(--primary-text) !important;
  background-color: var(--background-primary-3) !important;
  margin: 0px;
  height: 48px;
  border-radius: 0px 0px 8px 8px;
  display: flex;
  align-items: center;
  padding-left: 30px;
  position: relative;
  top: -41px;
  box-shadow: 0px -15px 28px -3px rgba(0, 0, 0, 0.1) !important;
}

.ant-breadcrumb a {
  color: var(--primary-text) !important;
}

.ant-breadcrumb>nz-breadcrumb-item:last-child,
.ant-breadcrumb>nz-breadcrumb-item:last-child a {
  color: var(--primary-text) !important;
  text-decoration: underline;
}

.ant-breadcrumb-separator {
  color: var(--primary-text) !important;
}