@import '../base/variables.scss';

.ant-form-header {
    font-size: 40px;
    font-weight: bold;
    line-height: 120%;
}

.ant-form-header-subtitle {
    font-size: 14px;
    line-height: 150%;
}

.km-input-number-code {
    .ant-input-number-handler-wrap {
        display: none !important;
    }

    .ant-input-number-input {
        text-align: center;
    }
}

.km-input-number-invalid {
    @extend .km-input-number-code;
}

.ant-input {
    padding-left: 2rem;
    color: var(--text-primary);
}

.ant-input-group.ant-input-group-compact>*:last-child,
.ant-input-group.ant-input-group-compact>.ant-select:last-child>.ant-select-selector,
.ant-input-group.ant-input-group-compact>.ant-cascader-picker:last-child .ant-input,
.ant-input-group.ant-input-group-compact>.ant-cascader-picker-focused:last-child .ant-input {
    height: 56px;
    width: 527px;
    background-color: var(--background-fifth);
    border-radius: 2px;
}

.ant-select-single.ant-select-show-arrow .ant-select-selection-item,
.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
    margin-top: 12px;
    color: $text-four-4 ;
}

.km-filter-container {
    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
        background-color: var(--background-fifth);
        border: 0px;
        display: flex;
        justify-content: center;
        align-items: end;
        padding-left: 0px;
    }

    .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
        box-shadow: none;
    }

    .ant-picker {
        background-color: $background-fifth;
        border: 0px;
        display: flex;
        justify-content: center;
        align-items: end;
        padding-left: 0px;
    }

    .ant-picker-focused {
        box-shadow: none;
    }

    .ant-select-single.ant-select-show-arrow .ant-select-selection-search {
        left: 0px;
    }

    .ant-collapse>.ant-collapse-item>.ant-collapse-header {
        background-color: var(--backgroud-secundary);
        height: 59px;
    }

    .ant-collapse>.ant-collapse-item>.ant-collapse-header {
        font-size: 20px;
        font-weight: bold;
        color: var(--text-third);
    }

    .ant-collapse-borderless>.ant-collapse-item>.ant-collapse-content>.ant-collapse-content-box {
        background-color: var(--backgroud-secundary);
    }

    .ant-collapse>.ant-collapse-item>.ant-collapse-header .ant-collapse-extra {
        margin-left: 8px;
    }
}

.km-form-container {

    padding: 15px 30px;

    .ant-form-item {
        padding: 5px 10px;
    }

    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
        border: 0px;
        height: 56px !important;
        display: flex;
        align-items: center !important;
        background-color: var(--background-fifth);
    }

    .ant-select-selection-search-input {
        margin-top: 12px !important;
    }

    .ant-picker {
        border: 0px;
        height: 56px;
        display: flex;
        align-items: center !important;
        background-color: var(--background-fifth);
    }

    .ant-picker-input>input {
        color: var(--text-primary);
        text-align: center;
    }

    .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
        display: flex !important;
        align-content: center !important;
    }


    .km-form-container .ant-select:not(.ant-select-customize-input) .ant-select-selector {
        align-items: center !important;
    }

    .ant-select-single.ant-select-show-arrow .ant-select-selection-item,
    .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
        margin-top: 0px;
    }

    .ant-input {
        padding-left: 16px;
        width: 100%;
        height: 56px !important;
        background-color: var(--background-fifth) !important;
        border-radius: 2px !important;
        border: 0px !important;
        border-radius: 8px !important;

        &.ant-input-status-error {
            border: 1px solid;
        }
    }

    .ant-picker {
        background-color: var(--background-fifth);

        &.ant-picker-status-error {
            border: 1px solid #ff4d4f;
        }
    }

    .ant-picker:hover {
        background-color: var(--background-fifth) !important;
    }

    .ant-select {
        background-color: var(--background-fifth);

        &.ant-select-status-error {
            border: 1px solid #ff4d4f;
        }
    }

    .ant-select-status-error.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input) .ant-select-selector {
        background-color: var(--background-fifth);
    }

    .ant-input::focus-visible {
        border: 0px;
        box-shadow: none;
    }

    .ant-input::placeholder {
        color: var(--text-four-2);
        padding-left: 16px;
    }

    .km-form-label {
        font-weight: 700;
        font-size: 16px;
        color: var(--text-primary);
        line-height: 150%;
    }

    .km-form-btn-section {
        width: 100%;
        display: flex;
        padding: 15px;
        justify-content: flex-end;
    }

    .nz-select {
        display: flex;
        align-items: center;
    }
}

.km-associate-container {
    .ant-tabs-nav-wrap {
        border-bottom: 1px solid var(--border-four-2);
    }

    .ant-tabs-nav-wrap {
        background-color: var(--background-primary-4);
    }

    .ant-tabs-tab {
        gap: 10px;
        background-color: var(--background-four);
        box-shadow: 0px 25px 50px -12px rgba(0, 0, 0, 0.25);
        border-radius: 0px 8px 0px 0px;
        padding: 12px 24px;
        border-width: 1px 1px 0px 1px;
        border-style: solid;
        border-color: var(--background-four);


    }

    .ant-tabs-tab-active {
        border-radius: 12px 12px 0px 0px;
        background-color: var(--background-primary);
        border-color: var(--background-primary);
    }

    .ant-tabs-tab-active .ant-tabs-tab-btn {
        color: #3D5AF1 !important;
    }

    .km-associate-input {
        padding-left: 16px;
        width: 100%;
        height: 56px !important;
        background-color: var(--backgroud-page) !important;
        border-radius: 10px !important;
        border: 1px solid var(--border-four-2) !important;
    }

    .ant-list {
        margin-top: 15px;
        border: 0px !important;
    }

    .ant-list-item {
        margin-top: 10px;
        color: var(--text-primary);
    }

    .km-associate-name,
    .km-associate-actions,
    .km-associate-has-code {
        border: 1px solid var(--border-four-2);
        background-color: var(--background-fifth);
        border-radius: 10px;
        padding: 1rem;
        flex: 1;
    }

    .km-associate-has-code {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;
        margin-right: 22px;
        margin-left: 22px;

        .ant-input {
            width: 250px;
            text-align: center;
        }

        .ant-input:focus {
            outline: none;
        }
    }
}


svg {
    color: var(--text-primary);
}

.ant-tabs-nav-wrap {
    color: var(--text-third);
}

.km-switch-status {
    .ant-switch {
        height: 40px;
        width: 80px;

        .ant-switch-handle {
            top: 10px;
            margin-left: 7px;
        }
    }

    .ant-switch-checked {
        background-color: var(--green-primary);
        height: 40px;
        width: 80px;

        .ant-switch-handle {
            top: 10px;
            margin-left: -7px;
        }
    }

    .ant-switch-inner {
        color: var(--text-primary);
        font-weight: 600;
    }

}



.container-switch-login {
    display: flex;
    justify-content: flex-end;
    margin-right: 16%;
    margin-top: 60px;

    .ant-switch {
        height: 40px;
        width: 80px;
        border-radius: 12px;

        .ant-switch-handle {
            top: 10px;
            margin-left: 7px;
        }

    }

    .ant-switch-checked {
        background-color: var(--background-third);
        height: 40px;
        width: 80px;
        border-radius: 12px;

        .ant-switch-handle {
            top: 10px;
            margin-left: -7px;
        }
    }

    .ant-switch-inner {
        color: var(--text-primary);
        font-weight: 600;
        display: flex;
        justify-content: center;
        align-items: center;
    }

}

.section-accordion {
    width: 100%;

    &.ant-collapse {
        background-color: var(--background-primary-4) !important;
        border: 0px !important;
        box-shadow: none;

        .ant-collapse-header {
            font-family: Poppins !important;
            font-size: 24px !important;
            font-weight: 700 !important;
            line-height: 33.6px !important;
            text-align: left !important;
            color: var(--text-primary);
        }
    }

}

@media (max-width: 571px) {

    .ant-steps {
        width: 70vw !important;
    }

    .ant-steps-item {
        width: 1rem !important;
    }

    .km-login-form {
        padding: 50px !important;

        .ant-input {
            width: 75vw !important;
        }
    }

    .km-forgot-password-form {
        .ant-input {
            width: 88vw !important;
        }

        button {
            margin-left: 0px !important;
        }
    }

    .km-set-new-password-form {
        .ant-input {
            width: 88vw !important;
        }

        button {
            margin-left: 0px !important;
        }
    }

    .km-all-done-header {
        padding-left: 50px !important;
        padding-right: 50px !important;
    }
}


.my-account-form {
    .km-form {
        padding: 40px !important;
        margin-top: 30px !important;
    }

    input {
        border: 0px;
    }

    label {
        margin: 10px !important;
    }

    .ant-form-item-control-input-content {
        margin: 10px !important;
    }
}

.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover {
    background-color: var(--background-fifth) !important;
}