@import "../base/variables.scss";

.notification-standard,
.notification-error {
  border-radius: 10px;
  background-color: var(--background-primary-4);

}

.notification-error .ant-notification-notice-message,
.notification-standard .ant-notification-notice-message {
  color: var(--text-third);
  font-size: 28px;
  font-weight: bold;
  line-height: 130%;
  margin: 0;
  margin-top: 48px;
}

.notification-error .ant-notification-notice-description,
.notification-standard .ant-notification-notice-description {
  color: var(--text-primary);
  font-size: 18px;
  line-height: 150%;
  margin: 0;

}

.notification-error .ant-notification-notice-close,
.notification-standard .ant-notification-notice-close {
  color: var(--text-primary);
  font-size: 28px;
}


.notification-error .ant-notification-notice-message {
  color: var(--notification-error);
}

/* Estilos para el botón de cierre de notificaciones personalizadas */
.custom-close-icon {
  /* Cambia el color del botón de cierre */
  /* Ajusta el tamaño del botón de cierre */
  font-size: 20px;
  /* Otros estilos según sea necesario */
}

.km-confirm-notification {
  width: 385px !important;


  .ant-modal-header {
    color: var(--text-primary);
    border-radius: 12px 12px 0px 0px;
    border: 0px;
    background-color: var(--background-primary-4);
  }

  .ant-modal-content {
    color: var(--text-primary);
    border-radius: 12px;
    background-color: var(--background-primary-4);
  }

  .ant-modal-title {
    @extend .title-large-secondary;
  }

  .ant-modal-body {
    color: var(--text-primary);
    padding-top: 0px;
    padding-bottom: 0px;
    background-color: var(--background-primary-4);
  }

  .ant-modal-footer {
    color: var(--text-primary);
    border: 0px;
    border-radius: 12px;
    background-color: var(--background-primary-4);
  }

  .ant-btn-dangerous {
    background-color: $background-seventh !important;
  }

  .ant-btn {
    border-radius: 6px;
    font-size: 14px;
    color: white;
    font-weight: 600;
    text-shadow: none;
    padding: 0px;
    height: 40px;
    padding: 0px 25px;
    border: 0px;
    background-color: $brand-primary;
    width: 7rem !important;
  }
}